import React, { useEffect, useState } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from 'react-router-dom';
import { addlegalandprivacy, editlegalandprivacy } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const FormView = () => {
    const location = useLocation();
    //   const navigate = useNavigate();
    const { push } = useHistory();
    const [type, setType] = useState('');
    const [status, setStatus] = useState('');
    const [image, setImage] = useState('');
    const [imageview, setImageview] = useState('');
    const [path, setPath] = useState('');
    console.log('pathhhhhhh',path)
    const [legal , setLegal] = useState('');

    useEffect(()=>{
        document.getElementById("managelegal").classList.add("active");
        if(window.innerWidth>767)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
          console.log(pagelinkofset,"pagelinkofset")
                 document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".themescroll li a.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
                }
            }
            
        }
    },[]);

    useEffect(() => {
        console.log("location data", location?.state?.state?.data,location?.state?.state?.path);
        setStatus(location?.state?.state?.data?.status);
        setType(location?.state?.state?.data?.type);
        setImage(location?.state?.state?.data?.image);
        setPath(location?.state?.state?.path)
        setLegal(location?.state?.state?.data?.data)
    }, []);

    const handlesubmit = async () => {
        console.log("submit", type?.length)
        if (type?.length > 0 && status?.length > 0) {
            var fileName = image?.name ? image?.name : image;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();


            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "webp" || extFile == "svg") {
                let payload = {
                    name: "legal",
                    image: image,
                    id: legal?.id,
                    type: type,
                    status: status
                };
                await editlegalandprivacy(payload).then((result) => {
                    console.log("add legal and privacy", result);
                    push("/managelegal");
                    window.location.reload();
                })
            }
            else {
                window.alert("Invalid file format !");
            }

        }
        else {
            window.alert("Fill all the fields !")
        }
    }


    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Edit Legal</h4>
                    <div className='admin-forms mb-4 '>
                        <div className='shadowbox p-4 br-10'>
                            <div className='row'>
                                <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Type *">
                                        <Form.Control placeholder='demo' type="text" defaultValue={type} onChange={(e) => { setType(e?.target?.value) }} />
                                    </FloatingLabel>
                                </div>
                                <div className='col-sm-6 mb-4'>
                                    <FloatingLabel controlId="floatingSelect" label="Status *">
                                        <Form.Select aria-label="Floating label select example" value={status} onChange={(e) => setStatus(e?.target?.value)}>
                                            <option value="">Select Status</option>
                                            <option >Active</option>
                                            <option >Inactive</option>

                                        </Form.Select>
                                    </FloatingLabel>
                                </div></div>


                            <div className='row'>
                                <div className='col-sm-6 mb-4'>
                                    <h6>Image *:</h6>
                                    <div className='upphoto my-3 ml-6'>
                                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                            <div className='d-none'>
                                                <span className='fa fa-photo'></span>

                                                <p>Upload image</p>
                                            </div>
                                            <img src={imageview ? imageview : API_URL + path + "/" + image} />

                                            <input type="file" accept=".jpg,.jpeg,.png" onChange={(e) => { console.log("e", e); setImageview(URL.createObjectURL(e?.target?.files[0])); setImage(e?.target?.files[0]); }} />
                                        </Button>
                                    </div>
                                </div>
                            </div>



                            <div className='px-4 text-end mt-3 btn_view_oneline' >
                                <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                                <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={() => { push("/managelegal") }}>Cancel</Button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default FormView