import React, { useState, useEffect } from "react";
import { Elements, LinkAuthenticationElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FRONT_URL } from "../../config/env";

function Confirmrefundpay(){
    const [message , setMessage] = useState("");
    const stripe = useStripe();
    const elements = useElements();

    const handlepay = async() => {
        const {error} = await stripe.confirmPayment({
            elements,
            
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: `${FRONT_URL}/account`,
            },
          });

          if(error){
            setMessage(error?.message);
          }
    }

    return(
        <>
            <LinkAuthenticationElement id="link-authentication-element"
            />
            <PaymentElement id="payment-element" />
            <span>{message}</span>
            <button onClick={() =>handlepay()} className='btn btn-theme'>Pay Now</button>
        </>
    )
}

export default Confirmrefundpay;