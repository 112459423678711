import React, { useEffect, useState } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';
// import 'react-summernote/lang/summernote-ru-RU';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import { addcmshook, editcmshook } from '../../../Hooks/adminHook';
import { useHistory, useLocation } from 'react-router-dom';
import { API_URL } from '../../../config/env';
import toast, { Toaster } from 'react-hot-toast';
const FormView = () => {
    const [status, setStatus] = useState("");
    console.log("status_data", status)
    const [name, setName] = useState("");
    const [content, setContent] = useState("");
    const [content1, setContent1] = useState("");
    const [image, setImage] = useState();
    const [imageview, setImageview] = useState();
    const [path, setPath] = useState();
    const [heading, setHeading] = useState('');
    const [identifier, setIdentifier] = useState('')
    const [cmsdata, setCmsData] = useState({});
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('')
    const [multipleimage, setMultipleImages] = useState([])
    const [multipleimageerror, setMultipleImageError] = useState('')
    const [multipleimageview, setMultipleImageView] = useState([])
    const [content2, setContent2] = useState('')
    console.log("multipleimageviewsdafasdf", multipleimageerror, multipleimageview, multipleimage)
    const { push } = useHistory();
    const location = useLocation();
    let regex = /^[0-9]+$/;

    useEffect(()=>{
        document.getElementById("managecms").classList.add("active");
        if(window.innerWidth>767)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
          console.log(pagelinkofset,"pagelinkofset")
                 document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".themescroll li a.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
                }
            }
            
        }
    },[]);

    useEffect(() => {
        console.log("location editcms", location?.state?.state, location?.state?.state?.identifier);
        setName(location?.state?.state?.name);
        setHeading(location?.state?.state?.heading)
        setContent(location?.state?.state?.content);
        setContent1(location?.state?.state?.content1)
        setContent2(location?.state?.state?.content2)
        setStatus(location?.state?.state?.status);
        setImage(location?.state?.state?.image);
        setPath(location?.state?.state?.path);
        setCmsData(location?.state?.state)
        setPhone(location?.state?.state?.phone);
        setAddress(location?.state?.state?.address)
        setIdentifier(location?.state?.state?.identifier)
        setMultipleImages(location?.state?.state?.multipleimage)
    }, [])

    const handlesubmit = async () => {
        if (status?.length > 0 && content?.length > 0) {
            let payload = {
                id: location?.state?.state?._id,
                address: address,
                name: name,
                //   status: (status == "Active" || status == "active") ? "active" : "Inactive",
                heading: heading,
                content: content,
                content1: content1,
                content2: content2,
                image: image,
                phone: phone,
                multipleimages: multipleimage,
                identifier: identifier

            };
            console.log("cmseditpayload", payload);
            await editcmshook(payload).then((result) => {
                console.log("edit cms hook result", result);
                push("/manage-cms")
            })
        }
        else {
            window.alert("Fill all the fields !..");
        }
    }


    const handleMultipleImage = async (e) => {
        try {
            console.log("e.target.images", e.target.name == "aboutsbanner_01", e.target.files, e.target.files?.length);
            let arr = [];
            if (e.target.name == "aboutsbanner_01") {
                if (e.target.files?.length <= 4) {
                    if (multipleimage?.length >= 4) {
                        let filenames = [];
                        for (let i = 0; i < Object.values(e.target.files)?.length; i++) {
                            console.log("for loop data", Object.values(e.target.files)[i])
                            let fileName = Object.values(e.target.files)[i]?.name;
                            var idxDot = fileName.lastIndexOf(".") + 1;
                            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                            filenames.push(extFile)
                        }
                        console.log("filesnamsdga", filenames, filenames.includes("png"));
                        if (filenames.includes("png")) {
                            setMultipleImageError('png image not allowed')
                        } else {
                            arr = [...arr, ...e.target.files];
                            setMultipleImageView(arr);
                            setMultipleImages(arr);
                        }

                    } else {
                        let filenames = [];
                        for (let i = 0; i < Object.values(e.target.files)?.length; i++) {
                            console.log("for loop data", Object.values(e.target.files)[i])
                            let fileName = Object.values(e.target.files)[i]?.name;
                            var idxDot = fileName.lastIndexOf(".") + 1;
                            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                            filenames.push(extFile)
                        }
                        console.log("filesnamsdga", filenames, filenames.includes("png"));
                        if (filenames.includes("png")) {
                            toast.error("Png not allowed")
                        } else {
                            setMultipleImageView([...multipleimageview, ...e.target.files])
                            setMultipleImages([...multipleimage, ...e.target.files])
                        }

                    }
                } else {
                    setMultipleImageError('CHOOSE ONLY 4 IMAGES')
                }
            } else {
                if (e.target.files?.length <= 2) {
                    if (multipleimage?.length >= 2) {
                        let filenames = [];
                        for (let i = 0; i < Object.values(e.target.files)?.length; i++) {
                            console.log("for loop data", Object.values(e.target.files)[i])
                            let fileName = Object.values(e.target.files)[i]?.name;
                            var idxDot = fileName.lastIndexOf(".") + 1;
                            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
                            filenames.push(extFile)
                        }
                        console.log("filesnamsdga", filenames, filenames.includes("png"));
                        if (filenames.includes("png")) {
                            setMultipleImageError('png image not allowed');
                        } else {
                            arr = [...arr, ...e.target.files];
                            setMultipleImageView(arr);
                            setMultipleImages(arr);
                        }

                    } else {
                        // if(filenames.some("png")){
                        //     toast.error("Png not allowed")
                        //    } else {
                        setMultipleImageView([...multipleimageview, ...e.target.files])
                        setMultipleImages([...multipleimage, ...e.target.files])

                    }
                } else {
                    setMultipleImageError('choose only 4 imgaes')
                }
            }
            // if()
        } catch (e) {
            console.log("handleMultipleImage_err", e)
        }
    }
    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Edit CMS</h4>
                    <div className='admin-forms mb-4 '>
                        <div className='shadowbox p-4 br-10'>
                            <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                                {<FloatingLabel
                                    controlId="floatingInput"
                                    label="Identifier"
                                    className="mb-3" >
                                    <Form.Control type="text" placeholder="name@example.com" defaultValue={identifier} readOnly={true} />
                                </FloatingLabel>}
                                {/* (identifier == "termsbanner_01" ||identifier == "termsbanner_01" || identifier == "contactbanner_01" || identifier == "aboutsbanner_01") && */}
                                {identifier && (identifier == "termsbanner_01" || identifier == "contactbanner_01" || identifier == "aboutsbanner_01" ) && <FloatingLabel
                                    controlId="floatingInput"
                                    label="Name"
                                    className="mb-3" >
                                    <Form.Control type="text" placeholder="name@example.com" defaultValue={name} onChange={(e) => { setName(e?.target?.value) }} />
                                </FloatingLabel>}
                                {/* <div className='col-sm-12 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Status *">
                                <Form.Select aria-label="Floating label select example" value={status} onChange={(e)=>setStatus(e?.target?.value)}>
                                    <option value ="" hidden>Select Status</option>
                                    <option value='Active'>Active</option>
                                    <option value='Inactive'>Inactive</option>
                                </Form.Select>
                            </FloatingLabel>
                            </div> */}
                            </div>


                            {(identifier == "contactbanner_01") && <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="phoneNumber"
                                    className="mb-3" >
                                    <Form.Control type="text" placeholder="name@example.com" defaultValue={phone} onChange={(e) => { if (e.target.value == '' || regex.test(e.target.value)) { setPhone(e.target.value) } }} />
                                </FloatingLabel>
                            </div>}
                            {(identifier == "contactbanner_01") && <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Address"
                                    className="mb-3" >
                                    <Form.Control type="text" placeholder="name@example.com" defaultValue={address} onChange={(e) => { setAddress(e?.target?.value) }} />
                                </FloatingLabel>
                            </div>}
                            {(identifier == "termsbanner_01" || identifier == "faqbanner_01") && <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Heading"
                                    className="mb-3" >
                                    <Form.Control type="text" placeholder="name@example.com" defaultValue={heading} onChange={(e) => { setHeading(e?.target?.value) }} />
                                </FloatingLabel>
                            </div>}




                            {(identifier == "termsbanner_01" || identifier == "contactbanner_01" || identifier == "aboutsbanner_01") && <div className='col-sm-12 mb-4'>
                                <h6>Image :</h6>
                                <div className='upphoto my-3 ml-6'>
                                    <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                        {/* <div className='d-none'>   */}
                                        <span className='fa fa-photo'></span>

                                        <p>Upload image</p>
                                        {/* </div> */}
                                        {image && <img src={imageview ? imageview : API_URL + path + "/" + image} />}

                                        <input type="file" accept=".jpg,.jpeg" onChange={(e) => { console.log("e", e); setImageview(URL.createObjectURL(e?.target?.files[0])); setImage(e?.target?.files[0]); }} />
                                    </Button>
                                </div>
                            </div>}


                            {/* {(identifier == "aboutsbanner_01") && <div className='col-sm-12 mb-4'>
                                <h6>Imagesss :</h6>
                                <div className='upphoto my-3 ml-6'>

                                    <Button variant="link" className='nounder text-dark me-2 mb-2'>


                                        <p>Multiple image Upload</p>
                                        {console.log("multipleimageview", multipleimageview, multipleimage, typeof multipleimageview)}

                                        <input type="file" accept=".jpg,.jpeg" name="aboutsbanner_01" multiple onChange={(e) => { handleMultipleImage(e); }} />
                                    </Button>

                                </div>
                          
                                <div className='multi_img_sec'>
                                    {multipleimage && <>{console.log('enterthis firest', identifier, multipleimageview, multipleimageview?.length, multipleimageview?.length >= 4)}
                                        {

                                            multipleimageview?.length >= 4 ? multipleimageview && multipleimageview?.length > 0 && multipleimageview.map((val, i) => {
                                                return (
                                                    <>{console.log('sdkjhfjsdgsd', val)}{val &&
                                                        <div className='img_div_multi'>
                                                            <img src={URL.createObjectURL(val)} />
                                                        </div>}</>
                                                )
                                            }
                                            ) : <>{console.log('[asdghfsgsjhghvndsgt]')}{multipleimage && multipleimage?.length > 0 && multipleimage?.map((item) => {
                                                return (
                                                    <>

                                                        {item &&
                                                            <div className='img_div_multi'>
                                                                <img src={API_URL + path + "/" + item} />
                                                            </div>}
                                                    </>

                                                )
                                            })}</>
                                        }</>}
                                </div>

                            <p className='text-danger'>{multipleimageerror}</p>



                            </div>} */}




                            {(identifier == "aboutsbanner_02") && <div className='col-sm-12 mb-4'>
                                <h6>Imagesss2    :</h6>
                                <div className='upphoto my-3 ml-6'>

                                    <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                        {/* <div className='d-none'>   */}
                                        {/* <span className='fa fa-photo'></span> */}

                                        <p>Multiple image Upload</p>
                                        {console.log("multipleimageview", multipleimageview, multipleimage, typeof multipleimageview)}

                                        <input type="file" accept=".jpg,.jpeg" name="aboutsbanner_02" multiple onChange={(e) => { handleMultipleImage(e); }} />
                                    </Button>
                                </div>

                                <div className='multi_img_sec'>
                                    {multipleimage && <>{console.log('enterthis firest', identifier, multipleimageview, multipleimageview?.length, multipleimageview?.length >= 4)}
                                        {

                                            multipleimageview?.length >= 2 ? multipleimageview && multipleimageview?.length > 0 && multipleimageview.map((val, i) => {
                                                return (
                                                    <>{console.log('sdkjhfjsdgsd', val)}{val &&
                                                        <div className='img_div_multi'>
                                                            <img src={URL.createObjectURL(val)} />
                                                        </div>}</>
                                                )
                                            }
                                            ) : <>{console.log('[asdghfsgsjhghvndsgt]')}{multipleimage && multipleimage?.length > 0 && multipleimage?.map((item) => {
                                                return (
                                                    <>

                                                        {item &&
                                                            <div className='img_div_multi'>
                                                                <img src={API_URL + path + "/" + item} />
                                                            </div>}
                                                    </>

                                                )
                                            })}</>
                                        }</>}
                                </div>
                            </div>}

                            {console.log('identifier == "termsbanner_01',identifier)}

                            {(identifier==''||(identifier && (identifier == "termsbanner_01" || identifier == "termsbanner_02" || identifier == "contactbanner_01" || identifier == "aboutsbanner_01" || identifier == "aboutsbanner_02"))) && 
                            <div className='px-4'>
                                {console.log('jdfjkdjkfhdhfg',content,(identifier && (identifier == "termsbanner_01" || identifier == "termsbanner_02")))}
                                <h5 className='mb-3'>Content *:</h5>

                                {<ReactSummernote
                                    // value="Default value"
                                    value={content}
                                    options={{
                                        //   lang: 'ru-RU',
                                        height: 350,
                                        dialogsInBody: true,
                                        toolbar: [
                                            ['style', ['style']],
                                            ['font', ['bold', 'underline', 'clear']],
                                            ['fontname', ['fontname']],
                                            ['para', ['ul', 'ol', 'paragraph']],
                                            ['table', ['table']],
                                            ['insert', ['link', 'picture', 'video']],
                                            ['view', ['fullscreen', 'codeview']]
                                        ]
                                    }}
                                    onChange={(e) => { console.log("e", e); setContent(e) }}
                                />}
                            </div>
                            }

                            {console.log("Content1 *:", identifier, identifier == "termsbanner_01")}
                            {(identifier == '' ||(identifier == "aboutsbanner_01" || identifier == "aboutsbanner_02")) && <div className='px-4'>
                                <h5 className='mb-3'>Content1 *:</h5>

                                {<ReactSummernote
                                    // value="Default value"
                                    value={content1}
                                    options={{
                                        //   lang: 'ru-RU',
                                        height: 350,
                                        dialogsInBody: true,
                                        toolbar: [
                                            ['style', ['style']],
                                            ['font', ['bold', 'underline', 'clear']],
                                            ['fontname', ['fontname']],
                                            ['para', ['ul', 'ol', 'paragraph']],
                                            ['table', ['table']],
                                            ['insert', ['link', 'picture', 'video']],
                                            ['view', ['fullscreen', 'codeview']]
                                        ]
                                    }}
                                    onChange={(e) => { console.log("e", e); setContent1(e) }}
                                />}
                            </div>}

                            {(identifier == '' ||(identifier == "aboutsbanner_01")) &&<div className='px-4'>
                                <h5 className='mb-3'>Content2 *:</h5>

                                {<ReactSummernote
                                    // value="Default value"
                                    value={content2}
                                    options={{
                                        //   lang: 'ru-RU',
                                        height: 350,
                                        dialogsInBody: true,
                                        toolbar: [
                                            ['style', ['style']],
                                            ['font', ['bold', 'underline', 'clear']],
                                            ['fontname', ['fontname']],
                                            ['para', ['ul', 'ol', 'paragraph']],
                                            ['table', ['table']],
                                            ['insert', ['link', 'picture', 'video']],
                                            ['view', ['fullscreen', 'codeview']]
                                        ]
                                    }}
                                    onChange={(e) => { console.log("e", e); setContent2(e) }}
                                />}
                            </div>}
                            <div className='px-4 text-end mt-3 btn_view_oneline' >
                                <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                                <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={() => { push("/manage-cms") }}>Cancel</Button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}


export default FormView