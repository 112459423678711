import React, { useState, Fragment, useEffect } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { CsvPropertyTypefileUpload, deletepropertytypehook, getpropertytypehook } from '../../../Hooks/adminHook';
import Papa from "papaparse";
import { toast, Toaster } from "react-hot-toast"
import { toastOptions } from '../../../config/env';
const TableView = () => {

    // const navigate = useNavigate();
    const { push } = useHistory();
    const [propertytype, setPropertytype] = useState([]);
    const [csvfile, setCsvfile] = useState({});
    const [csvfilename, setCsvfilename] = useState('');
    const [propertyTypeId, setPropertyTypeId] = useState('')

    useEffect(() => {
        async function fetchdata() {
            await getpropertytypehook().then((result) => {
                console.log("get property type hook result", result?.data?.data?.record[0]?.content);
                setPropertyTypeId(result?.data?.data?.record[0]._id);
                setPropertytype(result?.data?.data?.record[0]?.content);
            })
        }
        fetchdata();
    }, [])

    const deletepropertytype = async (data) => {
        console.log("delete property type data", data);
        await deletepropertytypehook(data?.id).then((result) => {
            console.log("delete property type hook", result);
            window.location.reload();
        })
    }

    const editpropertytype = async (data) => {
        console.log("edit property type data", data);
        push("/editpropertytype", { state: data });
    }

    const columns = [
        {
            key: "id",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "heading",
            text: "Heading",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "content",
            text: "Content",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editpropertytype(record)}
                            style={{ marginRight: '5px' }}
                        >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => uploadImage(record)}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deletepropertytype(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button>
                    </Fragment>
                );
            }
        }
    ]
    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        }, className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };


    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

    // const datas = {
    //   labels: labels,
    //   datasets: [
    //     {
    //       label: "My First dataset",
    //       backgroundColor: "rgb(255, 99, 132)",
    //       borderColor: "rgb(255, 99, 132)",
    //       data: [0, 10, 5, 2, 20, 30, 45],
    //     },
    //   ],
    // };


    const CsvfileReader = async (data) => {
        console.log('csvfilereader', data)
        try {
            let reqData = {
                '_id': propertyTypeId,
                'content': data
            }
            await CsvPropertyTypefileUpload(reqData).then((res) => {
                console.log('ressssssssssdds', res.data.data)
                let status = res.data.data.status;
                let message = res.data.data.message;
                console.log('sdhafajksdhfjasd', message)
                if (status == true) {
                    toast.success(message, toastOptions)
                }
                else {
                    toast.error(message, toastOptions)
                }
            })
        } catch (err) {

        }

    }


    const changeHandler = async (e) => {
        console.log('shdgfhjsdfgsjafdsf', e.target.files[0].name)
        let splitFile = e.target.files[0].name.split(".");
        console.log("splitFile", splitFile)
        if (splitFile[splitFile.length - 1] != "csv") {
            toast.error('Select your csv file !', toastOptions)
            return false
        }
        const valuesArray = [];
        if (splitFile[splitFile.length - 1] == "csv") {
            setCsvfilename(e.target.files[0].name)
            Papa.parse(e.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: async (results) => {
                    // this.setState({ valuesArray: results.data })

                    // Iterating data to get column name and their values
                    results.data.map((d) => {
                        console.log("===============", Object.keys(d));
                        valuesArray.push(Object.values(d));
                    });

                    // Filtered Values
                    setCsvfile({ valuesArray })
                    CsvfileReader(valuesArray)
                },

            });

        }

    }
    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Property Type</h4>
                    <div className='pl-4 text-end mt-3' >
                        <button className='btn btn-theme me-0' onClick={() => { push("/addpropertytype") }}>Add</button>
                    </div>



                    <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                        <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Property Type</h6>

                        <div className="row ">
                            <div className='col-md-12'>
                                <div className='text-right pr-1'>
                                    <div className='d-flex align-items-center' >

                                        {/* {console.log('sgdfasdfgasjkdf', csvfilename, csvfile)}
                                        {csvfilename ? csvfilename : ''}
                                        <span className='ml-auto ' >{csvfilename ? csvfilename : ''}</span>
                                        <div className='csvbtn ml-2'>
                                            <input type="file" name='csvfile' onChange={(e) => { changeHandler(e) }} />
                                            <button className='btn btn-secondary-outline '>CSV</button>
                                        </div> */}
                                    </div>


                                </div>
                            </div>
                        </div>


                        {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                        <DataTable striped responsive
                            config={config}
                            className={config.className}
                            records={propertytype}
                            columns={columns}

                        />
                        <Toaster />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableView