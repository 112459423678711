// import React, { Suspense, useEffect } from 'react';
// import Cookies from 'universal-cookie';
// import {
//   Route,
// } from "react-router-dom";
// import './Assets/css/styles.css';
// import Aos from 'aos';
// import 'aos/dist/aos.css';

// import AdminRoute from './AdminRoute';
// import Dashboard from './Components/Admin/Dashboard';
// import TableView from './Components/Admin/TableView';
// import FormView from './Components/Admin/FormView';

// import Adminlogin from './Components/Admin/Login';
// import Manageadmin from './Components/Admin/Table/manageadmin';
// import Addadmin from './Components/Admin/Form/Addadmin';
// import Editadmin from './Components/Admin/Form/Editadmin';
// import Manageuser from './Components/Admin/Table/manageuser';
// import Edituser from './Components/Admin/Form/Edituser';
// import Manageroom from './Components/Admin/Table/manageroom';
// import Editroom from './Components/Admin/Form/Editroom';
// import Managebooking from './Components/Admin/Table/managebooking';
// import Managepayout from './Components/Admin/Table/managepayout';
// import Payout from './Components/Admin/Form/payout';
// import Managehost from './Components/Admin/Table/managehost';
// import Edithost from './Components/Admin/Form/Edithost';
// import Managefloorplan from './Components/Admin/Table/managefloorplan';
// import Addfloorplan from './Components/Admin/Form/Addfloorplan';
// import Editfloorplan from './Components/Admin/Form/Editfloorplan';
// import Managepropertytype from './Components/Admin/Table/managepropertytype';
// import Addpropertytype from './Components/Admin/Form/Addpropertytype';
// import Editpropertytype from './Components/Admin/Form/Editpropertytype';
// import Managelegal from './Components/Admin/Table/managelegal';
// import Addlegal from './Components/Admin/Form/Addlegal';
// import Editlegal from './Components/Admin/Form/Editlegal';
// import Manageprivacytype from './Components/Admin/Table/manageprivacytype';
// import Addprivacytype from './Components/Admin/Form/Addprivacytype';
// import Editprivacytype from './Components/Admin/Form/Editprivacytype';
// import Managepropertytypegroup from './Components/Admin/Table/managepropertytypegroup';
// import Addpropertytypegroup from './Components/Admin/Form/Addpropertytypegroup';
// import Editpropertytypegroup from './Components/Admin/Form/Editpropertytypegroup';
// import Managedescription from './Components/Admin/Table/managedescription';
// import Adddescription from './Components/Admin/Form/Adddescription';
// import Editdescription from './Components/Admin/Form/Editdescription';
// import Manageamenities from './Components/Admin/Table/manageamenities';
// import Addamenity from './Components/Admin/Form/Addamenity';
// import Editamenity from './Components/Admin/Form/Editamenity';
// import Managecancellationpolicy from './Components/Admin/Table/managecancellationpolicy';
// import Addcancellationpolicy from './Components/Admin/Form/Addcancellationpolicy';
// import Editcancellationpolicy from './Components/Admin/Form/Editcancellationpolicy';

// // const Land = React.lazy(() => import('./Components/Landing/Land'));
// const adminRoutes = [
//   { path: '/', component: Adminlogin },
//   { path: '/dashboard', component: Dashboard },
//   { path: '/manageadmin', component: Manageadmin },
//   { path: '/addadmin', component: Addadmin },
//   { path: '/editadmin', component: Editadmin },
//   { path: '/manageuser', component: Manageuser },
//   { path: '/edituser', component: Edituser },
//   { path: '/manageroom', component: Manageroom },
//   { path: '/editroom', component: Editroom },
//   { path: '/managebooking', component: Managebooking },
//   { path: '/managepayout', component: Managepayout },
//   { path: '/payout', component: Payout },
//   { path: '/managehost', component: Managehost },
//   { path: '/edithost', component: Edithost },
//   { path: '/managefloorplan', component: Managefloorplan },
//   { path: '/addfloorplan', component: Addfloorplan },
//   { path: '/editfloorplan', component: Editfloorplan },
//   { path: '/managepropertytype', component: Managepropertytype },
//   { path: '/addpropertytype', component: Addpropertytype },
//   { path: '/editpropertytype', component: Editpropertytype },
//   { path: '/managelegal', component: Managelegal },
//   { path: '/addlegal', component: Addlegal },
//   { path: '/editlegal', component: Editlegal },
//   { path: '/manageprivacytype', component: Manageprivacytype },
//   { path: '/addprivacytype', component: Addprivacytype },
//   { path: '/editprivacytype', component: Editprivacytype },
//   { path: '/managepropertytypegroup', component: Managepropertytypegroup },
//   { path: '/addpropertytypegroup', component: Addpropertytypegroup },
//   { path: '/editpropertytypegroup', component: Editpropertytypegroup },
//   { path: '/managedescription', component: Managedescription },
//   { path: '/adddescription', component: Adddescription },
//   { path: '/editdescription', component: Editdescription },
//   { path: '/manageamenities', component: Manageamenities },
//   { path: '/addamenity', component: Addamenity },
//   { path: '/editamenity', component: Editamenity },
//   { path: '/managecancellationpolicy', component: Managecancellationpolicy },
//   { path: '/addcancellationpolicy', component: Addcancellationpolicy },
//   { path: '/editcancellationpolicy', component: Editcancellationpolicy }
// ]

// // const routedata =  adminRoutes.map(({path, component,name}, key) => <Route exact path={path}  component={component} key={key} name = {name} />)





// function App() {
//   const cookies = new Cookies();
//   useEffect(() => {
//     Aos.init({
//       duration: 1000,
//       easing: "ease-in-out",
//       once: true,
//       mirror: false
//     });
//     Aos.refresh();
//   }, []);



//   return (
//     <div className="App">
//       {/* <Suspense fallback={<div class="spinner">
//       <div></div>   
//       <div></div>    
//       <div></div>    
//       <div></div></div>}> */}
//   {/* <Route path="/dashboard" element={<Dashboard />} />, */}
//       <AdminRoute
// path="/(dashboard | manageadmin | addadmin |
    // editadmin | manageuser | edituser | manageroom |
//             editroom | managebooking | managepayout |payout |
//             managehost | edithost | managefloorplan | addfloorplan|
//             editfloorplan | managepropertytype | addpropertytype |
//             editpropertytype | managelegal |addlegal | editlegal |
//             manageprivacytype  | addprivacytype | editprivacytype |
//             managepropertytypegroup | addpropertytypegroup | editpropertytypegroup |
//             managedescription | adddescription | editdescription |
//             manageamenities | addamenity | editamenity | managecancellationpolicy |
//             addcancellationpolicy | editcancellationpolicy)/"
//         email={cookies.get("admin-email")} // condition
//       // isConnect={isConnect} //condition
//       // isAdmin={isAdmin} // condition
//       >
//         {adminRoutes} 
//         {/* [
//         <Route path="/dashboard" element={<Dashboard />} />
//         <Route path='/tableview' element={<TableView />} />
//         <Route path="/formview" element={<FormView />} />


//         <Route path='/manageadmin' element={<Manageadmin />} />
//         <Route path='/addadmin' element={<Addadmin />} />
//         <Route path='/editadmin' element={<Editadmin />} />
//         <Route path='/manageuser' element={<Manageuser />} />
//         <Route path='/edituser' element={<Edituser />} />
//         <Route path='/manageroom' element={<Manageroom />} />
//         <Route path='/editroom' element={<Editroom />} />
//         <Route path='/managebooking' element={<Managebooking />} />
//         <Route path='/managepayout' element={<Managepayout />} />
//         <Route path='/payout' element={<Payout />} />
//         <Route path='/managehost' element={<Managehost />} />
//         <Route path='/edithost' element={<Edithost />} />
//         <Route path='/managefloorplan' element={<Managefloorplan />} />
//         <Route path='/addfloorplan' element={<Addfloorplan />} />
//         <Route path='/editfloorplan' element={<Editfloorplan />} />
//         <Route path='/managepropertytype' element={<Managepropertytype />} />
//         <Route path='/addpropertytype' element={<Addpropertytype />} />
//         <Route path='/editpropertytype' element={<Editpropertytype />} />
//         <Route path='/managelegal' element={<Managelegal />} />
//         <Route path='/addlegal' element={<Addlegal />} />
//         <Route path='/editlegal' element={<Editlegal />} />
//         <Route path='/manageprivacytype' element={<Manageprivacytype />} />
//         <Route path='/addprivacytype' element={<Addprivacytype />} />
//         <Route path='/editprivacytype' element={<Editprivacytype />} />
//         <Route path='/managepropertytypegroup' element={<Managepropertytypegroup />} />
//         <Route path='/addpropertytypegroup' element={<Addpropertytypegroup />} />
//         <Route path='/editpropertytypegroup' element={<Editpropertytypegroup />} />
//         <Route path='/managedescription' element={<Managedescription />} />
//         <Route path='/adddescription' element={<Adddescription />} />
//         <Route path='/editdescription' element={<Editdescription />} />
//         <Route path='/manageamenities' element={<Manageamenities />} />
//         <Route path='/addamenity' element={<Addamenity />} />
//         <Route path='/editamenity' element={<Editamenity />} />
//         <Route path='/managecancellationpolicy' element={<Managecancellationpolicy />} />
//         <Route path='/addcancellationpolicy' element={<Addcancellationpolicy />} />
//         <Route path='/editcancellationpolicy' element={<Editcancellationpolicy />} />


//         ] */}

//       </AdminRoute>

//       {/* <BrowserRouter>
//     <Suspense fallback={<div class="spinner">
//       <div></div>   
//       <div></div>    
//       <div></div>    
//       <div></div></div>}>
        
//     <Routes>
//         <Route path="/dashboard" element={<Dashboard />} />
//         <Route path='/tableview' element={<TableView />} />
//         <Route path="/formview" element={<FormView />} />

        
//         <Route path='/manageadmin' element={<Manageadmin />} />
//         <Route path='/addadmin' element={<Addadmin />} />
//         <Route path='/editadmin' element={<Editadmin />} />
//         <Route path='/manageuser' element={<Manageuser />} />
//         <Route path='/edituser' element={<Edituser />} />
//         <Route path='/manageroom' element={<Manageroom />} />
//         <Route path='/editroom' element={<Editroom />} />
//         <Route path = '/managebooking' element = {<Managebooking />}/>
//         <Route path='/managepayout' element={<Managepayout />} />
//         <Route path='/payout' element={<Payout />} />
//         <Route path='/managehost' element={<Managehost />} />
//         <Route path='/edithost' element={<Edithost />} />
//         <Route path='/managefloorplan' element={<Managefloorplan />} />
//         <Route path='/addfloorplan' element={<Addfloorplan />} />
//         <Route path='/editfloorplan' element={<Editfloorplan />} />
//         <Route path='/managepropertytype' element={<Managepropertytype />} />
//         <Route path='/addpropertytype' element={<Addpropertytype />} />
//         <Route path='/editpropertytype' element={<Editpropertytype />} />
//         <Route path='/managelegal' element={<Managelegal />} />
//         <Route path='/addlegal' element={<Addlegal />} />
//         <Route path='/editlegal' element={<Editlegal />} />
//         <Route path='/manageprivacytype' element={<Manageprivacytype />} />
//         <Route path='/addprivacytype' element={<Addprivacytype />} />
//         <Route path='/editprivacytype' element={<Editprivacytype />} />
//         <Route path='/managepropertytypegroup' element={<Managepropertytypegroup />} />
//         <Route path='/addpropertytypegroup' element={<Addpropertytypegroup />} />
//         <Route path='/editpropertytypegroup' element={<Editpropertytypegroup />} />
//         <Route path='/managedescription' element={<Managedescription />} />
//         <Route path='/adddescription' element={<Adddescription />} />
//         <Route path='/editdescription' element={<Editdescription />} />
//         <Route path='/manageamenities' element={<Manageamenities />} />
//         <Route path='/addamenity' element={<Addamenity />} />
//         <Route path='/editamenity' element={<Editamenity />} />
//         <Route path='/managecancellationpolicy' element={<Managecancellationpolicy />} />
//         <Route path='/addcancellationpolicy' element={<Addcancellationpolicy />} />
//         <Route path='/editcancellationpolicy' element={<Editcancellationpolicy />} />
        
//         <Route path='/' element={<Adminlogin />} />
       




//         </Routes>
//       </Suspense>
//       </BrowserRouter> */}
//       {/* </Suspense> */}
//     </div>
//   );
// }

// export default App; 





/** lp */

import React, { Suspense, useEffect } from 'react';
import Cookies from 'universal-cookie';
import {
  Redirect,
  Route, Switch,
} from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';

import Aos from 'aos';
import 'aos/dist/aos.css';

import AdminRoute from './AdminRoute';
import Dashboard from './Components/Admin/Dashboard';
import TableView from './Components/Admin/TableView';
import FormView from './Components/Admin/FormView';

import Adminlogin from './Components/Admin/Login';
import Manageadmin from './Components/Admin/Table/manageadmin';
import Addadmin from './Components/Admin/Form/Addadmin';
import Editadmin from './Components/Admin/Form/Editadmin';
import Manageuser from './Components/Admin/Table/manageuser';
import Edituser from './Components/Admin/Form/Edituser';
import Manageroom from './Components/Admin/Table/manageroom';
import Editroom from './Components/Admin/Form/Editroom';
import Managebooking from './Components/Admin/Table/managebooking';
import Managepayout from './Components/Admin/Table/managepayout';
import Payout from './Components/Admin/Form/payout';
import Managehost from './Components/Admin/Table/managehost';
import Edithost from './Components/Admin/Form/Edithost';
import Managefloorplan from './Components/Admin/Table/managefloorplan';
import Addfloorplan from './Components/Admin/Form/Addfloorplan';
import Editfloorplan from './Components/Admin/Form/Editfloorplan';
import Managepropertytype from './Components/Admin/Table/managepropertytype';
import Addpropertytype from './Components/Admin/Form/Addpropertytype';
import Editpropertytype from './Components/Admin/Form/Editpropertytype';
import Managelegal from './Components/Admin/Table/managelegal';
import Addlegal from './Components/Admin/Form/Addlegal';
import Editlegal from './Components/Admin/Form/Editlegal';
import Manageprivacytype from './Components/Admin/Table/manageprivacytype';
import Addprivacytype from './Components/Admin/Form/Addprivacytype';
import Editprivacytype from './Components/Admin/Form/Editprivacytype';
import Managepropertytypegroup from './Components/Admin/Table/managepropertytypegroup';
import Addpropertytypegroup from './Components/Admin/Form/Addpropertytypegroup';
import Editpropertytypegroup from './Components/Admin/Form/Editpropertytypegroup';
import Managedescription from './Components/Admin/Table/managedescription';
import Adddescription from './Components/Admin/Form/Adddescription';
import Editdescription from './Components/Admin/Form/Editdescription';
import Manageamenities from './Components/Admin/Table/manageamenities';
import Addamenity from './Components/Admin/Form/Addamenity';
import Editamenity from './Components/Admin/Form/Editamenity';
import Managecancellationpolicy from './Components/Admin/Table/managecancellationpolicy';
import Addcancellationpolicy from './Components/Admin/Form/Addcancellationpolicy';
import Editcancellationpolicy from './Components/Admin/Form/Editcancellationpolicy';
import Managecoupon from './Components/Admin/Table/managecoupon';
import Addcoupon from './Components/Admin/Form/Addcoupon';
import Editcoupon from './Components/Admin/Form/Editcoupon';
import Sitesettings from './Components/Admin/Form/Sitesetting';
import Emailsettings from './Components/Admin/Form/Emailsetting';
import Managedispute from './Components/Admin/Table/managedispute';
import Updatedispute from './Components/Admin/Form/Updatedispute';
import Disputechat from './Components/Admin/Form/Disputechat';
import Joinus from './Components/Admin/Form/Joinuslinks';
import Experiencecategory from './Components/Admin/Table/manageexperiencecategory';
import Experienceprovidedetail from './Components/Admin/Table/managewhatprovide';
import Addexperiencecategory from './Components/Admin/Form/Addexperiencecaterory';
import Editexperiencecategory from './Components/Admin/Form/Editexperiencecategory';
import Addwhatyouprovide from './Components/Admin/Form/Addwhatprovide';
import Editwhatyouprovide from './Components/Admin/Form/Editwhatprovide';
import Managehostingexperience from './Components/Admin/Table/managehostingexperience';
import Edithostingexperience from './Components/Admin/Form/Edithostingexperience';
import Paymentgateway from './Components/Admin/Form/paymentgateway';
import Managereport from './Components/Admin/Table/managereport';
import Managefee from './Components/Admin/Table/Managefee';
import Addfee from './Components/Admin/Form/Addfee';
import Addfaq from './Components/Admin/Form/Addfaq';
import Editfee from './Components/Admin/Form/EditFee';
import Apicredential from './Components/Admin/Form/Apicredential';
import Managefinancereport from './Components/Admin/Table/Financereport';
import Sendemail from './Components/Admin/SendMail';


import Forgotpassword from './Components/Admin/Forget';
import Changepassword from './Components/Admin/ChangePassword';
import Managecategory from './Components/Admin/Table/managecategory';
import Addcategory from './Components/Admin/Form/Addcategory';
import Editcategory from './Components/Admin/Form/Editcategory';
import Managecrmdispute from './Components/Admin/Table/managecrmdispute';
import Managecms from './Components/Admin/Table/managecms';
import FaqCms from './Components/Admin/Table/faqcms'
import Addcms from './Components/Admin/Form/Addcms';
import Editcms from './Components/Admin/Form/Editcms';
import Editfaq from './Components/Admin/Form/Editfaq';
import Managewithdraw from './Components/Admin/Table/managewithdraw';
import Viewwithdraw from './Components/Admin/Form/viewwithdraw';
import Manageexperiencebooking from './Components/Admin/Table/manageexperiencebooking';
import Managerefund from "./Components/Admin/Table/managerefund";
import Viewrefund from "./Components/Admin/Form/viewrefund";

import EmailTemplate from './Components/Admin/Table/manageemailTemplates'
import EditEmailTemplate from './Components/Admin/Form/EditEmailTemplate'

import Managesubscription from './Components/Admin/Table/managesubscription';
import Addsubscription from './Components/Admin/Form/Addsubscription';
import Editsubscription from './Components/Admin/Form/Editsubscription';
import Managedate from './Components/Admin/Form/Managedate';
import Managesplitday from './Components/Admin/Form/Managesplitdays';

import toast, { Toaster } from 'react-hot-toast';

import './Assets/css/styles.css';
const stripePromise =  loadStripe("pk_test_51O7WG7SFP6kkyXcp6a2KEJuv4oQbJlDGBFwmxwLRdWcr6zGpTpt0TWkoQNXjLijHkZvsF2CG09aNEWn1ajDQGWVz00Xmerf8jZ");

const adminRoutes = [
  { path: '/login', component: Adminlogin },
  { path: '/', component: Dashboard },
  { path: '/dashboard', component: Dashboard },
  { path: '/manageadmin', component: Manageadmin },
  { path: '/addadmin', component: Addadmin },
  { path: '/editadmin', component: Editadmin },
  { path: '/manageuser', component: Manageuser },
  { path: '/edituser', component: Edituser },
  { path: '/manageroom', component: Manageroom },
  { path: '/editroom', component: Editroom },
  { path: '/managebooking', component: Managebooking },
  { path: '/managepayout', component: Managepayout },
  { path: '/payout', component: Payout },
  { path: '/managehost', component: Managehost },
  { path: '/edithost', component: Edithost },
  { path: '/managefloorplan', component: Managefloorplan },
  { path: '/addfloorplan', component: Addfloorplan },
  { path: '/editfloorplan', component: Editfloorplan },
  { path: '/managepropertytype', component: Managepropertytype },
  { path: '/addpropertytype', component: Addpropertytype },
  { path: '/editpropertytype', component: Editpropertytype },
  { path: '/managelegal', component: Managelegal },
  { path: '/addlegal', component: Addlegal },
  { path: '/editlegal', component: Editlegal },
  { path: '/manageprivacytype', component: Manageprivacytype },
  { path: '/addprivacytype', component: Addprivacytype },
  { path: '/editprivacytype', component: Editprivacytype },
  { path: '/managepropertytypegroup', component: Managepropertytypegroup },
  { path: '/addpropertytypegroup', component: Addpropertytypegroup },
  { path: '/editpropertytypegroup', component: Editpropertytypegroup },
  { path: '/managedescription', component: Managedescription },
  { path: '/adddescription', component: Adddescription },
  { path: '/editdescription', component: Editdescription },
  { path: '/manageamenities', component: Manageamenities },
  { path: '/addamenity', component: Addamenity },
  { path: '/editamenity', component: Editamenity },
  { path: '/managecancellationpolicy', component: Managecancellationpolicy },
  { path: '/addcancellationpolicy', component: Addcancellationpolicy },
  { path: '/editcancellationpolicy', component: Editcancellationpolicy },
  {path : '/managedispute' , component : Managedispute},
  // {path: '/managecoupon' , component : Managecoupon},
  {path : '/addcoupon' , component : Addcoupon},
  {path : '/editcoupon' , component : Editcoupon},
  {path : '/sitesettings' , component : Sitesettings},
  {path : '/updatedispute' , component : Updatedispute},
  {path : "/disputechat" , component : Disputechat},
  {path : "/emailsettings" , component : Emailsettings},
  {path : "/joinus" , component : Joinus},
  {path : "/experience-category" , component : Experiencecategory},
  {path : "/what-you-will-provide" , component : Experienceprovidedetail},
  {path : "/add-experience-category" , component : Addexperiencecategory},
  {path : "/edit-experience-category" , component : Editexperiencecategory},
  {path : "/add-what-you-provide" , component : Addwhatyouprovide},
  {path : "/edit-what-you-provide" , component : Editwhatyouprovide},
  {path : "/manage-hosting-experience" , component : Managehostingexperience},
  {path : "/edit-hosting-experience" , component : Edithostingexperience},
  {path : "/payment-gateway" , component : Paymentgateway},
  {path : "/manage-report" , component : Managereport},
  {path : "/manage-fee" , component : Managefee},
  {path : "/add-fee" , component : Addfee},
  {path : "/add-faq" , component : Addfaq},
  {path : "/edit-fee" , component : Editfee},
  {path : "/api-credential" , component: Apicredential},
  {path : "/manage-finance-report" , component : Managefinancereport},
  {path : "/send-email" , component : Sendemail},
  {path : "/manage-category" , component : Managecategory},
  {path : "/add-category" , component : Addcategory},
  {path : "/edit-category" , component : Editcategory},
  {path : "/manage-crm-dispute" , component : Managecrmdispute},
  {path : "/manage-cms" , component : Managecms},
  // /faq
  {path : "/faq" , component : FaqCms},
  {path : "/add-cms" , component : Addcms},
  {path : "/edit-cms" , component : Editcms},
  {path : "/edit-faq" , component : Editfaq},
  {path : "/manage-withdraw" , component : Managewithdraw},
  {path : "/view-withdraw" , component : Viewwithdraw},
  {path : "/manage-experience-booking" , component : Manageexperiencebooking},
  {path : "/manage-refund" , component : Managerefund},
  {path : "/view-refund" , component : Viewrefund},
  {path : "/manage-emailtemplates", component : EmailTemplate},
  { path: '/edit-emailtemplate', component: EditEmailTemplate },

  { path: '/manage-subscription', component: Managesubscription },
  { path: '/add-subscription', component: Addsubscription },
  { path: '/edit-subscription', component: Editsubscription },
  { path: '/manage-date', component: Managedate },
  { path: '/manage-splitday', component: Managesplitday },

].map(({path, component,name}, key) => <Route exact path={path}  component={component} key={key} name = {name} />)

function App() {
  const cookies = new Cookies();
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
    Aos.refresh();
  }, []);

   

  return (
    <div className="App">
       <div><Toaster /></div>
<Switch>
 <Route exact path={'/login'}  component={Adminlogin}/> 
<Route exact path = {'/forgot-password'}  component = {Forgotpassword} />
 <Route exact path = {'/change-password'} component = {Changepassword}/>
<AdminRoute
						// path="/(manageadmin   | addadmin | editadmin | manageuser | edituser |
            //   manageroom | editroom | managebooking | managepayout | payout | managehost |
            //   edithost | managefloorplan | addfloorplan | editfloorplan | managepropertytype |
            //   addpropertytype | editpropertytype | managelegal | addlegal | editlegal | manageprivacytype |
            //   addprivacytype | editprivacytype | managepropertytypegroup | /addpropertytypegroup | editpropertytypegroup |
            //   managedescription | adddescription | editdescription | manageamenities | addamenity | editamenity |
            //   managecancellationpolicy | addcancellationpolicy | editcancellationpolicy | managedispute |
            //   managecoupon | addcoupon | editcoupon | sitesettings | updatedispute | emailsettings)/" //path
            email = {cookies.get("admin-email")}
            // email = {async()=>await cookies.get("admin-email")}
						>
			{adminRoutes}
      
					</AdminRoute>
 
</Switch>


    </div>
  );
}

export default App; 