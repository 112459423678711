import React,{useEffect ,useState , Fragment} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { getalluserhook ,  edituserhook, deactivateuserhook, DateTimeForm} from '../../../Hooks/adminHook';
const TableView = () =>{
    const [users , setUsers] = useState([]);
    const [totalrecordcounts, setTotalRecordCounts] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    // const navigate = useNavigate();
    const {push} = useHistory();
    const location = useLocation();

    useEffect(()=> {
        async function fetchdata(){
            let reqData = {
                page : page,
                limit : limit
            }
            await getalluserhook(reqData).then((result) => {
                console.log("get all user result" , result);
                (result?.data?.data?.record).map((a,i) => {a.serial = i+1});
                setUsers(result?.data?.data?.record);
                setTotalRecordCounts(result?.data?.data?.count);
            })
        }
        fetchdata();
    } , [])

    const columns = [
        {
            key: "serial",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "firstName",
            text: "Firstname",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "lastName",
            text: "Lastname",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "email",
            text: "Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "countrycode",
            text: "Country",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "isHost",
            text: "IsHost",
            className: "name",
            align: "left",
            // sortable: true,
            cell: (data) =>(data.isHost?"Active" : "Inactive")
        },
        {
            key: "loginType",
            text: "Login Type",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "createdAt",
            text: "Created At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.createdAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.createdAt))?.split(",")[1])
        },
        {
            key: "updatedAt",
            text: "Updated At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.updatedAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.updatedAt))?.split(",")[1])
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editRecord(record)}
                            // style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deactivateuser(record)}
                            >
                            <i className="fa fa-trash"></i>
                        </button>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => WhiteList(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }
        // {
        //     name:"Id",
        //     reorder: true,
        //     cell: (data) =>(data.id)
        // },
        // {
        //     name:"Reservation Id",
        //     cell: (data) =>(data.reserveid)           
        // },
        // {
        //     name:"Room name",
        //     cell: (data) =>(data.Roomname)          
        // },
        // {
        //     name:"User Name",
        //     cell: (data) =>(data.username)
        // },
        // {
        //     name:"Email Id",
        //     cell: (data) =>(data.emailid)
        // },
        // {
        //     name:"User Date From",
        //     cell: (data) =>(data.userdatafrom)
        // },
        // {
        //     name:"User Date To",
        //     cell: (data) =>(data.userdatato)
        // },
        // {
        //     name:"Id Document",
        //     cell: (data) =>(data.iddocuments)
        // },
        // {
        //     name:"Status",
        //     cell: (data) =>(data.staus)
        // }
    ]
    // const data = [
    //     {
    //         id:1,
    //         reserveid:"1002",
    //         Roomname:"A Unique Studio",
    //         username:"David" ,
    //         emailid:"example@gmail.com",
    //         userdatafrom:"16.09.2022",
    //         userdatato:"22.09.2022",
    //         iddocuments:"file.pdf",
    //         staus:"approved"
    //     },
    //     {
    //         id:2,
    //         reserveid:"1002",
    //         Roomname:"A Unique Studio",
    //         username:"David" ,
    //         emailid:"example@gmail.com",
    //         userdatafrom:"16.09.2022",
    //         userdatato:"22.09.2022",
    //         iddocuments:"file.pdf",
    //         staus:"approved"
    //     },
    //     {
    //         id:3,
    //         reserveid:"1002",
    //         Roomname:"A Unique Studio",
    //         username:"David" ,
    //         emailid:"example@gmail.com",
    //         userdatafrom:"16.09.2022",
    //         userdatato:"22.09.2022",
    //         iddocuments:"file.pdf",
    //         staus:"approved"
    //     },
    //     {
    //         id:4,
    //         reserveid:"1002",
    //         Roomname:"A Unique Studio",
    //         username:"David" ,
    //         emailid:"example@gmail.com",
    //         userdatafrom:"16.09.2022",
    //         userdatato:"22.09.2022",
    //         iddocuments:"file.pdf",
    //         staus:"approved"
    //     }
     
    // ]

    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  
    const editRecord = async(data) => {
        // console.log("edit record data" , data);
        // let payload = {
        //     id : 
        // }
        // await edituserhook(data._id).then((result) => {
        //     console.log("edit record result" , result);
        // })
        push("/edituser" , {state : data});
    }

    const deactivateuser = async(data) => {
        console.log("deactivate user data" , data);
        await deactivateuserhook(data._id).then((result) => {
            console.log("deactivate user result" , result);
            window.location.reload();
        })
    }

    const pageChange = async (index) => {

        try {
            console.log("index data", index)
            let reqData = {
                page: index.page_number,
                limit : index.page_size,
                search: index.filter_value
            }
            await getalluserhook(reqData).then((result) => {
                console.log("get all user result" , result);
                (result?.data?.data?.record).map((a,i) => {a.serial = i+1});
                setUsers(result?.data?.data?.record);
            })
            console.log('index_data', index)
        } catch (err) {
            console.log("pageChange_err", err)
        }
    } 


const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

// const datas = {
//   labels: labels,
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "rgb(255, 99, 132)",
//       borderColor: "rgb(255, 99, 132)",
//       data: [0, 10, 5, 2, 20, 30, 45],
//     },
//   ],
// };
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Guest Users</h4>
                {/* <button className='btn btn-theme me-3' onClick={()=>{push("/admin/adduser")}}>Add</button> */}
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View User</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={users}
                        columns={columns}
                        dynamic={true}
                        total_record={totalrecordcounts}
                        onChange={pageChange}
                       

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView