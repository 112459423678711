import React,{useState ,  Component, useEffect } from 'react';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
// import 'react-summernote/lang/summernote-ru-RU';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
// import ReactQuill from 'react-quill';
import AdminHeader from './AdminHeader';
import Sidebar from './Sidebar';
import {FloatingLabel, Form,Button, InputGroup} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import prof from '../../Assets/images/prof1.jpg';
import { getalluserhook, sendemailhook } from '../../Hooks/adminHook';



const SendMail = () =>{
    const email = <>Email id<span className='text-danger ms-1'>*</span></> 
    const subject = <>Subject<span className='text-danger ms-1'>*</span></> 
    const [to , setTo] = useState('');
    const [subjects , setSubjects] = useState('');
    const [description , setDescription] = useState('');
    const [alluser, setAllUser] = useState([])


    useEffect(() => {
        async function fetchdata() {
            await getalluserhook().then((result) => {
                setAllUser(result?.data?.data?.record)
            })
        }
        fetchdata()
    }, []);

    const handlesend = async()=>{
        var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        console.log("length" , to?.length , subjects?.length , subjects);
        if(to?.length > 0 && subjects?.length >0 && description?.length > 0){
            if(validRegex.test(to)){
                const payload = {
                    email : to,
                    subject : subjects,
                    description : description
                };
                console.log("payload" , payload);
                await sendemailhook(payload).then(()=>{
                    window.alert("Email send sucessfully !")
                });
                window.location.reload();
            }
            else{
                window.alert("Enter valid email address!..")
            }
        }
        else{
            window.alert("Fill all the fields");
        }
    }
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
                <div className='cont admin-forms'>
                    <div className='container'>
                        <div className='col-md-10 m-auto'>
                            <h4 className='adminblue my-5'>Send mail</h4>
                            <div className='shadowbox   p-5 br-10'>
                                <FloatingLabel controlId="floatingSelect" label="Works with selects">
                                    <Form.Select aria-label="Floating label select example"
                                        onChange={(e) => {
                                            setTo(e?.target?.value);
                                        }}>
                                        <option value=''>Select the host user</option>
                                        {alluser?.map((data, i) => <option >{data?.email}</option>)}
                                    </Form.Select>
                                </FloatingLabel>
                                <br></br>
                                {/* <FloatingLabel controlId="floatingInput" label={email} className="mb-4"> 
                                <Form.Control type="email" placeholder="name@example.com" onChange={(e)=>{setTo(e?.target?.value)}}/>
                            </FloatingLabel> */}
                                <FloatingLabel controlId="floatingInput" label={subject} className="mb-3">
                                    <Form.Control type="text" placeholder="name@example.com" onChange={(e) => { setSubjects(e?.target?.value) }} />
                                </FloatingLabel>
                                <p>Description *:</p>
                            <ReactSummernote
                            value="Default value"
                            options={{
                                //   lang: 'ru-RU',
                                height: 350,
                                dialogsInBody: true,
                                toolbar: [
                                    ['style', ['style']],
                                    ['font', ['bold', 'underline', 'clear']],
                                    ['fontname', ['fontname']],
                                    ['para', ['ul', 'ol', 'paragraph']],
                                    ['table', ['table']],
                                    ['insert', ['link', 'picture', 'video']],
                                    ['view', ['fullscreen', 'codeview']]
                                ]
                            }}
                            onChange={(e)=>{console.log("e" ,e);setDescription(e)}}
                        />


{/* <ReactQuill  value={Description}
                         theme="snow"
                         type="text" 
                         onChange={(e)=>{setDescription(e?.target?.value)}}
                          ></ReactQuill> */}
                        <div className='px-4 text-end mt-3' >
                            {/* <button className='btn btn-theme me-3' >Submit</button> */}
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={handlesend} >Send</Button>
                        </div>
                        </div>
                        
                        
                    </div>

                   
                    
                </div>
                
               </div> 
            </div>
        </div>
    )
}

export default SendMail