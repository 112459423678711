import React,{useState, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { addpropertytypehook } from '../../../Hooks/adminHook';
const FormView = () =>{
    // const navigate = useNavigate();
    const {push} = useHistory();
    const [heading , setHeading] = useState('');
    const [content , setContent] = useState('');
    const [status , setStatus] = useState('');
  
  const handlesubmit = async() => {
      if(heading?.length > 0 && content?.length >0 && status?.length >0) 
      {
        let payload = {
            heading : heading,
            content : content,
            status : status
        }
        console.log("payload" , payload);
        await addpropertytypehook(payload).then((result) => {
            console.log("add property type hook result" , result);
            push("/managepropertytype");
        })
      }
      else {
          window.alert("Fill all the fields !");
      }
  }

  useEffect(()=>{
    document.getElementById("managepropertytype").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
},[]);

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Add Property Type</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Heading *">
                                    <Form.Control type="text" placeholder='demo' onChange={(e) => setHeading(e?.target?.value)} />
                                </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Content *">
                                    <Form.Control type="text" placeholder='demo' onChange={(e) => setContent(e?.target?.value)} />
                                </FloatingLabel> 
                            </div>
                        </div>
                        
                        <div className='row' >
                        <div className='col-sm-6 mb-4'>
                        <FloatingLabel controlId="floatingSelect" label="Status *">
                            <Form.Select aria-label="Floating label select example" onChange={(e) => setStatus(e?.target?.value)}>
                                <option value = "">Select Status</option>
                                <option >Active</option>
                                <option >Inactive</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                          
                        </div>
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'onClick={()=> {push("/managepropertytype")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView