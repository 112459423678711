import React,{useState , useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { acceptuserverificationhook, edituserhook, rejectuserverificationhook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const FormView = () =>{
    const location = useLocation();
    // const navigate = useNavigate();
    const {push} = useHistory();
  const [singleuser , setSingleuser] = useState({});
  const [status , setStatus] = useState(false);
  const [kyc , setKyc] = useState({});

  useEffect(() => {
      console.log("location darta" , location?.state?.state);
      setSingleuser(location?.state?.state)
      setStatus(location?.state?.state?.status)
      setKyc(location?.state?.state?.govtproof[0]);
  }, []);

  useEffect(()=>{
    document.getElementById("manageguestuser").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
        },[]);
  
const handlesubmit = async() => {
    console.log("handle submit");
    if(status?.length>0)
    {
        let payload = {
            id : location?.state?.state?._id,
            status : status
        };
        await edituserhook(payload).then((result) => {
            console.log("edit userhook result" , result);
            push("/manageuser");
        })
    }
}

const handlecancel = async() =>{
    console.log("cancel");
    push("/manageuser");
}

const handleaccept = async() => {
    await acceptuserverificationhook(singleuser._id).then((result) => {
        console.log("accept user verification hook" , result);
        window.alert("Verification Accepted Sucessfully");
    })
}

const handlereject = async() => {
    console.log("handle reject");
    if(kyc?.status){
    await rejectuserverificationhook(singleuser._id).then((result) => {
        console.log("handl;e reject result" , result);
        window.alert("Verification Rejected Sucessfully");
    })
    }
}

// const handlereject = async() => {
//     console.log("handle reject");
//     if(singleuser?.govtproof?.status){
//     await rejectuserverificationhook(singleuser._id).then((result) => {
//         console.log("handl;e reject result" , result);
//         window.alert("Verification Rejected Sucessfully");
//     })
//     }
// }



    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader/></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit Guest User</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="First Name"
                                 >
                            <Form.Control type="text"  value = {singleuser?.firstName} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Last Name"
                                 >
                                <Form.Control type="text" value = {singleuser?.lastName} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row' >
                        <div className='col-sm-6 mb-4'>
                             <FloatingLabel
                                controlId="floatingInput"
                                label="Gender"
                                 >
                                <Form.Control type="text" value = {singleuser?.gender} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                                <FloatingLabel controlId="floatingSelect" label="Date Of Birth">
                                    <Form.Control type="text" placeholder="name@example.com" readOnly={true} value = {singleuser?.dob?.slice(0,10)}/>
                                </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email"
                                 >
                            <Form.Control type="text" placeholder="name@example.com" value ={singleuser?.email} readOnly = {true}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Country"
                                 >
                                <Form.Control type="text" placeholder="name@example.com" value ={singleuser?.countrycode} readOnly = {true}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Login Type"
                                 >
                            <Form.Control type="text" placeholder="name@example.com" value ={singleuser?.loginType} readOnly = {true}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Is Host"
                                 >
                                <Form.Control type="text" placeholder="name@example.com" value = {singleuser?.isHost ? "Active" : "Inactive"} readOnly = {true}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Status"
                                 >
                            <Form.Select aria-label="Floating label select example" value = {status} onChange={e => setStatus(e.target.value)}>
                                <option>Active</option>
                                <option>Inactive</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Govt Proof"
                                 >
                                <Form.Control type="text" placeholder="name@example.com" value ={kyc?.proof ? kyc?.proof : "Not Provided!"} readOnly = {true} />
                            </FloatingLabel>
                            </div>

                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Govt Proof Status"
                                 >
                                <Form.Control type="text" placeholder="name@example.com" value ={kyc?.verification ? kyc?.verification : "Not Provided!"} readOnly = {true} />
                            </FloatingLabel>
                            </div>
                        </div>
                      
                   
                        {kyc?.status && <h6 className='d-block'>Govt Proof Image :</h6>}
                            
                            <div className='d-flex upimages upimages_edit mb-3 px-0' >
                            {kyc?.status && kyc?.govtidimg?.map((data) => <a href = {API_URL+kyc?.path+singleuser?.email+"/"+data} target="_blank">
                            <img src={API_URL+kyc?.path+singleuser?.email+"/"+data} onclick="window.open(this.src, '_blank');" />
                            </a>)}
                                
                            </div>
                            
                            {/* {singleuser?.govtproof?.govtidimg?.map((data)=>{<a href= {API_URL+data} className='fa fa-file h-50 w-50'>aaaa</a>})} */}
                            {/* </FloatingLabel> */}
                          
                                {/* <Form.Control type="date" placeholder="name@example.com" className='' /> */}
                                {kyc?.status &&
                                <div className='px-0 mb-3'>
                                    
                                        <button className='btn btn-theme me-2 mb-3' onClick={handleaccept}>Accept</button>
                                        <button className='btn btn-theme mb-3' onClick={handlereject}>Decline</button>
                                    
                            {/* <button className='btn btn-theme me-2' onClick={handleaccept}>Accept</button>
                            <button className='btn btn-theme' onClick={handlereject}>Decline</button> */}
                        </div>}

                   {/* Bank detail  */}

                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Bank Name"
                                 >
                            <Form.Control type="text"  value = {singleuser?.bankname} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Branch Name"
                                 >
                                <Form.Control type="text" value = {singleuser?.branchname} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Bank Holder Name"
                                 >
                            <Form.Control type="text"  value = {singleuser?.holdername} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Account Number"
                                 >
                                <Form.Control type="text" value = {singleuser?.accountno} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="IFSC Code"
                                 >
                            <Form.Control type="text"  value = {singleuser?.ifsccode} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                        </div>
                        
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={handlecancel}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView