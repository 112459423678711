import React, { useState, Fragment, useEffect } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { getalladmins, deactivateadminhook } from '../../../Hooks/adminHook';
const TableView = () => {
    // const navigate = useNavigate();
    const { push } = useHistory();
    const [adminuser, setAdminuser] = useState([]);
    const [totalrecordcounts, setTotalRecordCounts] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    async function fetchdata() {
        let reqData = {
            page: page,
            limit: limit
        }
        let result = await getalladmins(reqData);
        console.log("retrive db", result?.data?.data?.record);
        (result?.data?.data?.record).map((a, i) => { a.serial = i + 1 });
        console.log("retrive db", result?.data?.data?.record);
        setAdminuser(result?.data?.data?.record);
        setTotalRecordCounts(result?.data?.data?.count);
    }
    
    useEffect(() => {

        fetchdata();
    }, [])
    const columns = [
        {
            key: "serial",
            text: "S.no.",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "name",
            text: "Name",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "email",
            text: "Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "level",
            text: "Role",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editRecord(record)}
                        // style={{ marginRight: '5px' }}
                        >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deactivate(record)}
                            style={{ marginRight: '15px' }}>
                            <i className="fa fa-trash" ></i>
                        </button>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => WhiteList(record)}
                            style={{ marginRight: '10px' }}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }

    ]
    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "admins",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            csv: true,
            print: true,

        },
        className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
    const editRecord = async (data) => {
        console.log("edit record function");
        push("/editadmin", { state: data });
    }

    const deactivate = async (data) => {
        console.log("deactivate admin", data?._id);

        await deactivateadminhook(data?._id).then((result) => {
            console.log("deactivate adminresult", result);
            window.location.reload()
        })
    }

    const pageChange = async (index) => {

        try {
            let reqData = {
                page: index.page_number,
                limit: index.page_size,
                search: index.filter_value
            }
            let result = await getalladmins(reqData);
            console.log("retrive db", result?.data?.data?.record);
            (result?.data?.data?.record).map((a, i) => { a.serial = i + 1 });
            console.log("retrive db", result?.data?.data?.record);
            setAdminuser(result?.data?.data?.record);
            console.log('index_data', index)
        } catch (err) {
            console.log("pageChange_err", err)
        }
    }
    // const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

    // const datas = {
    //   labels: labels,
    //   datasets: [
    //     {
    //       label: "My First dataset",
    //       backgroundColor: "rgb(255, 99, 132)",
    //       borderColor: "rgb(255, 99, 132)",
    //       data: [0, 10, 5, 2, 20, 30, 45],
    //     },
    //   ],
    // };
    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Admin Users</h4>
                    <div className='pl-4 text-end mt-3' >
                        <button className='btn btn-theme me-0' onClick={() => { push("/addadmin") }}>Add</button>
                    </div>



                    <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                        <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Admin</h6>
                        {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                        <DataTable striped responsive
                            config={config}
                            className={config.className}
                            records={adminuser}
                            columns={columns}
                            dynamic={true}
                            total_record={totalrecordcounts}
                            onChange={pageChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableView