import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import validator from 'validator'
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getjoinushook, updatejoinushook } from '../../../Hooks/adminHook';
const FormView = () =>{
  const [joinus , setJoinus] = useState({});
  const [facebook , setFacebook] = useState('');
  const [twitter , setTwitter] = useState('');
  const [linkedin , setLinkedin] = useState('');
  const [pinterest , setPinterest] = useState('');
  const [youtube , setYoutube] = useState('');
  const [instagram , setInstagram] = useState('');
  const [playstore, setPlaystore] = useState('');
  const [appstore , setAppstore] = useState('');
  useEffect(()=>{
    async function fetchdata(){
        await getjoinushook().then((result)=>{
            console.log("get joinus hook result" , result);
            setJoinus(result?.data?.data?.record[0]);
            setFacebook(result?.data?.data?.record[0]?.details?.facebook);
            setTwitter(result?.data?.data?.record[0]?.details?.twitter);
            setLinkedin(result?.data?.data?.record[0]?.details?.linkedin);
            setPinterest(result?.data?.data?.record[0]?.details?.pinterest);
            setYoutube(result?.data?.data?.record[0]?.details?.youtube);
            setInstagram(result?.data?.data?.record[0]?.details?.instagram);
            setPlaystore(result?.data?.data?.record[0]?.details?.playstore);
            setAppstore(result?.data?.data?.record[0]?.details?.appstore);
        })
    }
    fetchdata();
  },[])

  const handlesubmit = async()=>{
    console.log("facebook?.length" , facebook?.length , facebook);
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                        '(\\#[-a-z\\d_]*)?$','i');
    if(facebook?.length >0  && twitter?.length>0   &&
        linkedin?.length>0  && pinterest?.length>0  &&
        youtube?.length>0   && instagram?.length>0 &&
        playstore?.length>0  && appstore?.length >0){
        if( validator.isURL(facebook) &&  validator.isURL(twitter) && 
         validator.isURL(linkedin) &&
         validator.isURL(pinterest) && 
         validator.isURL(youtube) && 
        validator.isURL(instagram) &&
        validator.isURL(playstore) &&  validator.isURL(appstore)){
            let payload = {
                id : joinus?._id,
                facebook : facebook,
                twitter : twitter,
                linkedin : linkedin,
                pinterest : pinterest,
                youtube : youtube,
                instagram : instagram,
                playstore : playstore,
                appstore : appstore
            };
            console.log("payload" , payload);
            await updatejoinushook(payload).then((result)=>{
                console.log("update join us result" , result);
                window.location.reload();
            })
        }
        else{
            window.alert("Fields must be url!..")
        }
    
    }
    else{
        window.alert("Fill all the fields");
    }
    }
  


    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Join Us</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Facebook"
                                >
                                <Form.Control placeholder="demo" type="text" value={facebook} onChange={(e)=>{setFacebook(e?.target?.value)}} />
                            </FloatingLabel>
                          
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Twitter"
                                >
                            <Form.Control placeholder="demo" type="text" defaultValue={twitter} onChange ={(e)=>{setTwitter(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="LinkedIn"
                                >
                                <Form.Control placeholder="demo" type="text" defaultValue={linkedin} onChange={(e)=>{setLinkedin(e?.target?.value)}} />
                            </FloatingLabel>
                       
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Pinterest"
                                >
                            <Form.Control placeholder="demo" type="text" defaultValue={pinterest} onChange ={(e)=>{setPinterest(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Youtube"
                                >
                                <Form.Control placeholder="demo" type="text" defaultValue={youtube} onChange={(e)=>{setYoutube(e?.target?.value)}} />
                            </FloatingLabel>
                           
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Instagram"
                                >
                                <Form.Control placeholder="demo" type="text" defaultValue={instagram} onChange={(e)=>{setInstagram(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Playstore"
                                >
                            <Form.Control placeholder="demo" type="text" defaultValue={playstore} onChange ={(e)=>{setPlaystore(e?.target?.value)}} />
                            </FloatingLabel>
                           
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Appstore"
                                >
                                <Form.Control placeholder="demo" type="text" defaultValue={appstore} onChange={(e)=>{setAppstore(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                     
                        <div className='px-4 text-end mt-3' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            {/* <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button> */}
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView